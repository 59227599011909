<template>
	<div class="addProperty">
		<!--        导航-->
		<div>
<!--			<van-nav-bar title="物业交割单" left-arrow @click-left="onClickLeft" />-->
			<rxNavBar title="物业交割单"></rxNavBar>
		</div>

		<div class="container" v-if="swi != 1">
			<!-- 物业交割单 -->
			<div class="body">
				<div class="icon">
					<img src="../../../assets/images/life-pay.png">
				</div>
				<div class="lifePay">生活缴费</div>
			</div>
			<!-- 电表 -->
			<!-- <div  v-for=" (item,i) in contractProperty" :key="i" > -->
			<div class="payInfo">
				<div class="payInfo-image" v-if="contractProperty.wattMeterPath != '' ">
					<img :src="contractProperty.wattMeterPath" @click="previewPropertyDetailPhotos(2,0,contractProperty.wattMeterPath)">
				</div>

				<div class="payInfo-image" v-if="contractProperty.wattMeterPath == ''  ">
					<img src="../../../assets/images/electricIcon.png" alt="">
				</div>

				<div class="payInfo-right">
					<div class="payInfo-name">电表</div>
					<div class="payInfo-number">{{contractProperty.wattMeterCodeTypeStr}}：{{contractProperty.wattMeterCode}}</div>
					<div class="payInfo-account">底表数：{{contractProperty.wattMeterNumber}}</div>
          <div class="payInfo-remark">备注：{{contractProperty.wattMeterRemarks}}</div>
				</div>
			</div>
			<!-- 水表 -->
			<div class="payInfo">
				<div class="payInfo-image" v-if="contractProperty.waterPath != ''  ">
					<img :src="contractProperty.waterPath" @click="previewPropertyDetailPhotos(2,0,contractProperty.waterPath)">
				</div>

				<div class="payInfo-image" v-if="contractProperty.waterPath == ''  ">
					<img src="../../../assets/images/waterIcon.png" alt="">
				</div>

				<div class="payInfo-right">
					<div class="payInfo-name">水表</div>
					<div class="payInfo-number">{{contractProperty.waterMeterCodeTypeStr}}：{{contractProperty.waterMeterCode}}</div>
					<div class="payInfo-account">底表数：{{contractProperty.waterMeterNumber}}</div>
          <div class="payInfo-remark">备注：{{contractProperty.waterMeterRemarks}}</div>
				</div>
			</div>
      <!-- 热水表 -->
      <div class="payInfo" v-if="contractProperty.hotWaterMeterCode != ''">
        <div class="payInfo-image" v-if="contractProperty.hotWaterPath != ''  ">
          <img :src="contractProperty.hotWaterPath" @click="previewPropertyDetailPhotos(2,0,contractProperty.hotWaterPath)">
        </div>

        <div class="payInfo-image" v-if="contractProperty.hotWaterPath == ''  ">
          <img src="../../../assets/images/waterIcon.png" alt="">
        </div>

        <div class="payInfo-right">
          <div class="payInfo-name">热水表</div>
          <div class="payInfo-number">{{contractProperty.hotWaterMeterCodeTypeStr}}：{{contractProperty.hotWaterMeterCode}}</div>
          <div class="payInfo-account">底表数：{{contractProperty.hotWaterMeterNumber}}</div>
          <div class="payInfo-remark">备注：{{contractProperty.hotWaterMeterRemarks}}</div>
        </div>
      </div>
			<!-- 燃气表 -->
			<div class="payInfo">
				<div class="payInfo-image" v-if="contractProperty.gasMeterPath != ''  ">
					<img :src="contractProperty.gasMeterPath" @click="previewPropertyDetailPhotos(2,0,contractProperty.gasMeterPath)">
				</div>
				<div class="payInfo-image" v-if="contractProperty.gasMeterPath == ''  ">
					<img src="../../../assets/images/fireIcon.png" alt="">
				</div>
				<div class="payInfo-right">
					<div class="payInfo-name">燃气表</div>
					<div class="payInfo-number">{{contractProperty.gasMeterCodeTypeStr}}：{{contractProperty.gasMeterCode}}</div>
					<div class="payInfo-account">底表数：{{contractProperty.gasMeterNumber}}</div>
          <div class="payInfo-remark">备注：{{contractProperty.gasMeterRemarks}}</div>
				</div>
			</div>
			<div class="payInfoB">
				<span style="">交割备注：</span><span class="payInfoText" style="" >{{contractProperty.description}}</span>
			</div>

      <!-- 检查项-->
      <div class="middle" v-if="condition&&roomPricingTagDetail.length!=0">
        <div class="icon">
          <img src="../../../assets/images/furniture-icon.png">
        </div>
        <div class="lifeHome">房间检查项</div>
      </div>
      <div class="finuture" v-if="condition&&roomPricingTagDetail.length!=0">
        <div class="goodsInfo clearfix">
          <div v-for="(ite,cidx) in roomPricingTagDetail" :key="cidx">
            <div class="goods-name">{{ite.pricingTagName}}</div>
            <div class="goods-image" @click="showPropertyDetailPhotos(ite)">查看实物图></div>
            <div class="goods-status"><span>存在：{{ite.itemCount==1?'有':'无'}}</span><span>状态：{{ite.dictionaryTitle}}</span></div>
            <div class="goods-describe">描述：{{ite.itemDescription}}</div>
          </div>
          <!--配置物品弹出层-->
          <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
            <div class="swiper_show" ref="wrapperBox">
              <div class="content">
                <div class="imgWrap" v-for="(url,i) in propertyDetailPhotos" :key="i" @click="previewPropertyDetailPhotos(1,i,'')">
                  <van-image width="100" height="75" fit="contain" radius="10" :src="url" />
                </div>
              </div>
              <div class="num">{{propertyDetailPhotos.length}}图</div>
            </div>
          </van-popup>
        </div>
      </div>

      <div class="middle" v-if="condition&&houseType=='1'&&publicPricingTagDetail.length!=0">
        <div class="icon">
          <img src="../../../assets/images/furniture-icon.png">
        </div>
        <div class="lifeHome">公区检查项</div>
      </div>
      <div class="finuture" v-if="condition&&houseType=='1'&&publicPricingTagDetail.length!=0">
        <div class="goodsInfo clearfix">
          <div v-for="(ite,cidx) in publicPricingTagDetail" :key="cidx">
            <div class="goods-name">{{ite.pricingTagName}}</div>
            <div class="goods-image" @click="showPropertyDetailPhotos(ite)">查看实物图></div>
            <div class="goods-status"><span>存在：{{ite.itemCount==1?'有':'无'}}</span><span>状态：{{ite.dictionaryTitle}}</span></div>
            <div class="goods-describe">描述：{{ite.itemDescription}}</div>
          </div>
          <!--配置物品弹出层-->
          <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
            <div class="swiper_show" ref="wrapperBox">
              <div class="content">
                <div class="imgWrap" v-for="(url,i) in propertyDetailPhotos" :key="i" @click="previewPropertyDetailPhotos(1,i,'')">
                  <van-image width="100" height="75" fit="contain" radius="10" :src="url" />
                </div>
              </div>
              <div class="num">{{propertyDetailPhotos.length}}图</div>
            </div>
          </van-popup>
        </div>
      </div>

      <!--物品交割-->
			<div v-for=" (item,i) in contractPropertyDetail " :key="i">
				<div class="middle">
					<div class="icon">
						<img src="../../../assets/images/furniture-icon.png">
					</div>
					<div class="lifeHome">{{item.name}}</div>
				</div>
				<div class="finuture">
					<div class="goodsInfo clearfix">
						<div v-for="(ite,cidx) in item.sublist" :key="cidx">
							<div class="goods-name">{{ite.itemName}}</div>
							<div class="goods-image" @click="showPropertyDetailPhotos(ite)">查看实物图></div>
							<div class="goods-describe">描述：{{ite.itemDescription}}</div>
						</div>
						<!--配置物品弹出层-->
						<van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
							<div class="swiper_show" ref="wrapperBox">
								<div class="content">
									<div class="imgWrap" v-for="(url,i) in propertyDetailPhotos" :key="i" @click="previewPropertyDetailPhotos(1,i,'')">
										<van-image width="100" height="75" fit="contain" radius="10" :src="url" />
									</div>
								</div>
								<div class="num">{{propertyDetailPhotos.length}}图</div>
							</div>
						</van-popup>
					</div>
				</div>
			</div>
			<!-- </div> -->


			<div class="foot">
			</div>
			<!-- 待确认 -->
			<div v-if="contractProperty.isUserComfirm!=1&&added">
				<div class="bottom">
          <div class="bottom-left" v-if="condition">待租客确认</div>
          <div class="bottom-left" v-else>待业主确认</div>
					<div class="bottom-right" @click="confirm(contractProperty.id)" v-if="!cpid">确认</div>
				</div>
			</div>
			<!-- 已确认 -->
			<div v-if="contractProperty.isUserComfirm==1&&added">
				<div class="confirm">
					<div class="confirm-left">已确认，确认人：{{contractProperty.userName}}</div>
					<div class="confirm-right">{{contractProperty.conformTime}}</div>
				</div>
			</div>
      <!-- 为确认 -->
      <div v-if="!added">
        <div class="confirm">
          <div class="confirm-left">暂未添物业加交割单</div>
        </div>
      </div>
		</div>

		<div class="container" v-if="swi == '1'">
			<!-- 物业交割单 -->
			<div class="body">
				<div class="icon">
					<img src="../../../assets/images/life-pay.png">
				</div>
				<div class="lifePay">生活缴费</div>
			</div>
			<!-- 电表 -->
			<div v-for=" (item,i) in tenantContractProperty" :key="i">
				<div class="payInfo">
					<div class="payInfo-image" v-if="item.wattMeterPath != '' ">
						<img :src="item.wattMeterPath" @click="previewPropertyDetailPhotos(2,0,item.wattMeterPath)">
					</div>

					<div class="payInfo-image" v-if="item.wattMeterPath == ''  ">
						<img src="../../../assets/images/electricIcon.png" alt="">
					</div>

					<div class="payInfo-right">
						<div class="payInfo-name">电表</div>
            <div class="payInfo-number">{{item.wattMeterCodeTypeStr}}：{{item.wattMeterCode}}</div>
            <div class="payInfo-account">底表数：{{item.wattMeterNumber}}</div>
            <div class="payInfo-remark">备注：{{item.wattMeterRemarks}}</div>
					</div>
				</div>
				<!-- 水表 -->
				<div class="payInfo">
					<div class="payInfo-image" v-if="item.waterPath != ''  ">
						<img :src="item.waterPath" @click="previewPropertyDetailPhotos(2,0,item.waterPath)">
					</div>

					<div class="payInfo-image" v-if="item.waterPath == ''  ">
						<img src="../../../assets/images/waterIcon.png" alt="">
					</div>

					<div class="payInfo-right">
						<div class="payInfo-name">水表</div>
            <div class="payInfo-number">{{item.waterMeterCodeTypeStr}}：{{contractProperty.waterMeterCode}}</div>
            <div class="payInfo-account">底表数：{{item.waterMeterNumber}}</div>
            <div class="payInfo-remark">备注：{{item.waterMeterRemarks}}</div>
					</div>
				</div>
				<!-- 燃气表 -->
				<div class="payInfo">
					<div class="payInfo-image" v-if="item.gasMeterPath != ''  ">
						<img :src="item.gasMeterPath" @click="previewPropertyDetailPhotos(2,0,item.gasMeterPath)">
					</div>
					<div class="payInfo-image" v-if="item.gasMeterPath == ''  ">
						<img src="../../../assets/images/fireIcon.png" alt="">
					</div>
					<div class="payInfo-right">
						<div class="payInfo-name">燃气表</div>
            <div class="payInfo-number">{{item.gasMeterCodeTypeStr}}：{{item.gasMeterCode}}</div>
            <div class="payInfo-account">底表数：{{item.gasMeterNumber}}</div>
            <div class="payInfo-remark">备注：{{item.gasMeterRemarks}}</div>

					</div>

				</div>
				<div class="payInfoB">
					<span style="">交割备注：</span><span class="payInfoText" style="">{{item.description}}</span>
				</div>
        <!-- 检查项-->
        <div class="middle" v-if="condition">
          <div class="icon">
            <img src="../../../assets/images/furniture-icon.png">
          </div>
          <div class="lifeHome">房间检查项</div>
        </div>
        <div class="finuture" v-if="condition">
          <div class="goodsInfo clearfix">
            <div v-for="(ite,cidx) in roomPricingTagDetail" :key="cidx">
              <div class="goods-name">{{ite.pricingTagName}}</div>
              <div class="goods-image" @click="showPropertyDetailPhotos(ite)">查看实物图></div>
              <div class="goods-status"><span>存在：{{ite.itemCount==1?'有':'无'}}</span><span>状态：{{ite.dictionaryTitle}}</span></div>
              <div class="goods-describe">描述：{{ite.itemDescription}}</div>
            </div>
            <!--配置物品弹出层-->
            <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
              <div class="swiper_show" ref="wrapperBox">
                <div class="content">
                  <div class="imgWrap" v-for="(url,i) in propertyDetailPhotos" :key="i" @click="previewPropertyDetailPhotos(1,i,'')">
                    <van-image width="100" height="75" fit="contain" radius="10" :src="url" />
                  </div>
                </div>
                <div class="num">{{propertyDetailPhotos.length}}图</div>
              </div>
            </van-popup>
          </div>
        </div>

        <div class="middle" v-if="condition">
          <div class="icon">
            <img src="../../../assets/images/furniture-icon.png">
          </div>
          <div class="lifeHome">公区检查项</div>
        </div>
        <div class="finuture" v-if="condition">
          <div class="goodsInfo clearfix">
            <div v-for="(ite,cidx) in publicPricingTagDetail" :key="cidx">
              <div class="goods-name">{{ite.pricingTagName}}</div>
              <div class="goods-image" @click="showPropertyDetailPhotos(ite)">查看实物图></div>
              <div class="goods-status"><span>存在：{{ite.itemCount==1?'有':'无'}}</span><span>状态：{{ite.dictionaryTitle}}</span></div>
              <div class="goods-describe">描述：{{ite.itemDescription}}</div>
            </div>
            <!--配置物品弹出层-->
            <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
              <div class="swiper_show" ref="wrapperBox">
                <div class="content">
                  <div class="imgWrap" v-for="(url,i) in propertyDetailPhotos" :key="i" @click="previewPropertyDetailPhotos(1,i,'')">
                    <van-image width="100" height="75" fit="contain" radius="10" :src="url" />
                  </div>
                </div>
                <div class="num">{{propertyDetailPhotos.length}}图</div>
              </div>
            </van-popup>
          </div>
        </div>

        <!--  物品交割-->
				<div v-for=" (item,i) in tenantPropertyDetail " :key="i">
					<div class="middle">
						<div class="icon">
							<img src="../../../assets/images/furniture-icon.png">
						</div>
						<div class="lifeHome">{{item.name}}</div>
					</div>
					<div class="finuture">
						<div class="goodsInfo clearfix">
							<div v-for="(ite,cidx) in item.sublist" :key="cidx">
								<div class="goods-name">{{ite.itemName}}</div>
								<div class="goods-image" @click="showPropertyDetailPhotos(ite)">查看实物图></div>
								<div class="goods-describe">描述：{{ite.itemDescription}}</div>
							</div>
							<!--配置物品弹出层-->
							<van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
								<div class="swiper_show" ref="wrapperBox">
									<div class="content">
										<div class="imgWrap" v-for="(url,i) in propertyDetailPhotos" :key="i" @click="previewPropertyDetailPhotos(1,i,'')">
											<van-image width="100" height="75" fit="contain" radius="10" :src="url" />
										</div>
									</div>
									<div class="num">{{propertyDetailPhotos.length}}图</div>
								</div>
							</van-popup>
						</div>
					</div>
				</div>

				<div class="foot">
				</div>
				<!-- 待确认 -->
				<div v-if="item.isUserComfirm!=1">
					<div class="bottom">
            <div class="bottom-left" v-if="condition">待租客确认</div>
						<div class="bottom-left" v-else>待业主确认</div>

						<div class="bottom-right" @click="confirm(item.id)" v-if="!cpid">确认</div>
					</div>
				</div>
				<!-- 已确认 -->
				<div v-if="item.isUserComfirm==1">
					<div class="confirm">
						<div class="confirm-left">已确认，确认人：{{item.userName}}</div>
						<div class="confirm-right">{{item.conformTime}}</div>
					</div>
				</div>
			</div>
		</div>






	</div>
</template>

<script>
	import {
		NavBar,
		Image as VanImage,
		Uploader,
		popup,
		Toast,
		ImagePreview
	} from 'vant';
	import {
		queryContractProperty,
		confirmContractProperty
	} from "../../../getData/getData";
	import {
		globaluserId,
		responseUtil
	} from "../../../libs/rongxunUtil";
	import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	export default {
		components: {
			[NavBar.name]: NavBar,
			[Uploader.name]: Uploader,
			[popup.name]: popup,
			[VanImage.name]: VanImage,
			[Toast.name]: Toast,
			rxNavBar,
		},

		data() {
			return {
				//展示配置物品图片信息
				propertyDetailPhotos: [],
				show: false, //显示弹出层
				contractProperty: [],
				contractPropertyDetail: [],
        roomPricingTagDetail:[],
        publicPricingTagDetail:[],
				tenantContractProperty: [],
				tenantPropertyDetail: [],
				contractId: this.$route.query.contract_id,
        condition: this.$route.query.condition,
				id: '',
				swi: '',
        cpid:'',
        relievecp_id:'',
        added:true,

        pricingTagList:[],
        houseType:'',
			}
		},
		mounted() {
			this.initData()
      this.cpid = this.$route.query.cpid
      this.relievecp_id = this.$route.query.relievecp_id
      this.houseType = this.$route.query.houseType

      if (this.$route.query.swi == '1') {   //装修进有多个表
				this.swi = '1'
			}
		},
		methods: {
			//物业交割单初始化数据（解除合同审批、租约变更合同、本人合同交割、装修）
			initData() {
				let that = this
				let initData = {};
				initData.contract_id = that.$route.query.contract_id //合同id
				initData.contractPropertyId = that.$route.query.contractPropertyId?that.$route.query.contractPropertyId:that.$route.query.cpid //租约变更id
        if(that.$route.query.type=='confirmQuit'){  //如果是解除审批进入，传另一个值
            initData.ch_id = that.$route.query.ch_id
            initData.contractPropertyId = this.$route.query.relievecp_id && this.$route.query.relievecp_id!=''?this.$route.query.relievecp_id:this.$route.query.cpid
        }
				// initData.contract_id= 88;//合同id
				queryContractProperty(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						// console.log(response.data.data)
            if(JSON.stringify(response.data.data) == '{}'){   //未添加
              that.added = false
            }
						if (response.data.data && JSON.stringify(response.data.data) != '{}') {
              that.added = true
							that.contractProperty = response.data.data.contractProperty //水电表信息
							that.contractPropertyDetail = response.data.data.contractPropertyDetail //1套表物品详情信息
              that.roomPricingTagDetail = response.data.data.roomPricingTagDetail  //一套表的检查项
              that.publicPricingTagDetail = response.data.data.publicPricingTagDetail  //一套表的检查项

              that.tenantContractProperty = response.data.data.tenantContractProperty //多个水电表信息
							that.tenantPropertyDetail = response.data.data.tenantPropertyDetail //多个表的物品详情信息
						}

						//     console.log(response.data.data)

					})
				})
			},
			//点击配置物品详情按钮
			showPropertyDetailPhotos(ite) {
				// console.log(ite)
				//this.propertyDetailPhotos
				if (ite.photoList == undefined) {
					Toast('暂无实物图')
				} else {
					this.propertyDetailPhotos = []
					let list = ite.photoList
					for (let i = 0; i < list.length; i++) {
						this.propertyDetailPhotos.push(list[i].path)
					}
					this.show = true
				}

			},


			previewPropertyDetailPhotos(type,start,path) {
				var images = []
				if(type == 1){
					images = this.propertyDetailPhotos
				}else{
					images.push(path)
				}
				ImagePreview({
					images: images,
					startPosition: start,
				});
			},

			//确认
			confirm(id) {
				this.id = id
				this.getConfirmContractProperty();
			},
			/**
			 * 物业交割确认接口
			 */
			getConfirmContractProperty(e) {
				console.log(e)
				let that = this
				let initData = {};
				initData.id = that.id; //物业交割单id
				initData.user_id = globaluserId(); //用户id
				confirmContractProperty(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						that.initData()
					})
				})
			},

			//点击后退方法
			onClickLeft() {
				this.$router.go(-1)
			},

		}
	}
</script>

<style lang="less" scoped>
	.container {
		width: 100%;
		height: 100%;
		background-color: #fafafa;
		overflow: hidden;
	}

	.body {
		width: 100%;
		height: 25px;
		margin-top: 31.5px;
		margin-bottom: 4px;
		display: flex;
	}

	.body .icon {
		width: 25px;
		height: 25px;
		margin-left: 22px;
	}

	.body .icon image {
		width: 100%;
		height: 100%;
	}

	.body .lifePay {
		width: 75px;
		height: 17px;
		margin-top: 6px;
		color: rgba(34, 34, 34, 1);
		font-size: 12px;
		font-weight: 700;
		font-family: PingFangSC-Semibold;
	}

	.payInfo {
    display: flex;
    align-items: center;
    justify-content: space-around;
		width: 332px;
		//height: 105px;
		margin-left: 22px;
		margin-bottom: 15px;
		border-radius: 8px;
		background-color: rgba(255, 255, 255, 1);
	}

	.payInfo .payInfo-image {
		width: 100px;
		height: 75px;
		//margin-top: 10px;
		//margin-left: 14px;
		float: left;
		border-radius: 8px;
		background-color: rgba(245, 245, 245, 1);
	}

	.payInfo .payInfo-image img {
		width: 100%;
		height: 100%;
	}

	.payInfo .payInfo-right {
		width: 50%;
		//height: 100%;
		//float: left;
		//margin-left: 8px;
		//overflow: hidden;
	}

	.payInfo .payInfo-right .payInfo-name {
		//width: 100%;
		//height: 21px;
		margin-top: 15px;
		color: rgba(34, 34, 34, 1);
		font-size: 15px;
		//line-height: 21px;
		font-weight: 700;
		text-align: left;
		font-family: PingFangSC-Semibold;
	}

	.payInfo .payInfo-right .payInfo-number {
		//width: 100%;
		//height: 17px;
		margin-top: 8px;
		//line-height: 17px;
		color: rgba(102, 102, 102, 1);
		font-size: 12px;
		//text-align: left;
		font-family: PingFangSC-Regular;
	}

	.payInfo .payInfo-right .payInfo-account {
		//width: 100%;
		//height: 17px;
		margin-top: 2px;
		//line-height: 17px;
    //text-align: left;

    color: rgba(102, 102, 102, 1);
		font-size: 12px;
		font-family: PingFangSC-Regular;
	}
  .payInfo .payInfo-right .payInfo-remark {
    //width: 100%;
    //height: 17px;
    margin-top: 2px;
    margin-bottom: 15px;
    //line-height: 17px;
    //text-align: left;

    color: rgba(102, 102, 102, 1);
    font-size: 12px;
    font-family: PingFangSC-Regular;
  }

	.middle {
		width: 100%;
		height: 40px;
		overflow: hidden;
	}

	.middle .icon {
		width: 25px;
		height: 25px;
		float: left;
		margin-left: 20px;
		margin-top: 11px;
	}

	.middle .icon image {
		width: 100%;
		height: 100%;
	}

	.middle .lifeHome {
		float: left;
		width: 86px;
		height: 17px;
		margin-top: 14px;
		color: rgba(34, 34, 34, 1);
		font-size: 12px;
		font-weight: 700;
		line-height: 17px;
		font-family: PingFangSC-Semibold;
	}

	.clearfix::before,
	.clearfix::after {
		content: '';
		display: table;
		clear: both;
	}

	.goodsInfo {
		width: 332px;
		height: auto;
		margin-left: 22px;
		margin-bottom: 14px;
		border-radius: 16px;
		background-color: rgba(255, 255, 255, 1);
	}

	.goodsInfo .goods-name {
		min-width: 40px;
		height: 20px;
		margin-top: 12px;
		margin-left: 22px;
		float: left;
		color: rgba(34, 34, 34, 1);
		line-height: 20px;
		font-weight: 700;
		font-size: 14px;
		text-align: left;
		font-family: PingFangSC-Semibold;
	}

	.goodsInfo .goods-image {
		width: 68px;
		height: 17px;
		margin-top: 15px;
		margin-right: 20px;
		float: right;
		line-height: 17px;
		color: rgba(49, 145, 255, 1);
		font-size: 12px;
		text-align: left;
		font-family: PingFangSC-Regular;
		white-space: nowrap;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
	}

  .goodsInfo .goods-status {
    width: 290px;
    height: auto;
    //margin-top: 5px;
    //margin-bottom: 15px;
    margin-left: 22px;
    float: left;
    line-height: 17px;
    color: rgba(153, 153, 153, 1);
    font-size: 12px;
    text-align: left;
    font-family: PingFangSC-Regular;
    >span{
      margin-right: 35px;
    }
  }
	.goodsInfo .goods-describe {
		width: 290px;
		height: auto;
		margin-top: 5px;
		margin-bottom: 15px;
		margin-left: 22px;
		float: left;
		line-height: 17px;
		color: rgba(153, 153, 153, 1);
		font-size: 12px;
		text-align: left;
		font-family: PingFangSC-Regular;
	}



	.finuture {
		width: 100%;
		height: auto;

	}

	.foot {
		width: 100%;
		height: 100px;
	}


	.bottom {
		width: 100%;
		height: 65px;
		position: fixed;
		bottom: 0px;
		background-color: rgba(248, 248, 248, 1);
		box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.07);
	}

	.bottom .bottom-left {
		float: left;
		width: 60px;
		height: 17px;
		line-height: 17px;
		margin-top: 24px;
		margin-left: 20px;
		color: rgba(255, 93, 59, 1);
		font-size: 12px;
		text-align: left;
		font-family: PingFangSC-Regular;
	}

	.bottom .bottom-right {
		float: right;
		width: 91px;
		height: 40px;
		line-height: 40px;
		margin-top: 12px;
		margin-right: 13px;
		color: rgba(255, 255, 255, 1);
		background: linear-gradient(to right, #ffc274, #ff5d3b);
		border-radius: 8px;
		box-shadow: 0px 1px 1px 0px rgba(188, 0, 0, 0.2);
		font-size: 14px;
		text-align: center;
		font-family: PingFangSC-Semibold;
	}

	.confirm {
		width: 100%;
		position: fixed;
		z-index: 999;
		bottom: 0px;
		height: 65px;
		background-color: rgba(248, 248, 248, 1);
		box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.07);
	}

	.confirm .confirm-left {
		float: left;
		width: 230px;
		height: 20px;
		font-weight: 700;
		line-height: 20px;
		margin-top: 22px;
		margin-left: 20px;
		color: rgba(255, 93, 59, 1);
		font-size: 14px;
		text-align: left;
		font-family: PingFangSC-Regular;
	}

	.confirm .confirm-right {
		float: right;
		min-width: 85px;
		height: 20px;
		line-height: 20px;
		margin-top: 22px;
		margin-right: 20px;
		color: rgba(153, 153, 153, 1);
		font-size: 14px;
		text-align: left;
		font-family: PingFangSC-Semibold
	}

	// 图片墙
	.swiper_show {
		width: 325px;
		position: relative;
		margin: 15px 10px;
		height: 75px;

		.content {
			position: absolute;
			display: flex;
			justify-content: space-around;
			left: 0px;

			.imgWrap {
				margin-right: 10px;
			}
		}

		.num {
			position: absolute;
			left: 4px;
			top: 6px;
			font-size: 10px;
			background-color: rgb(0, 0, 0);
			opacity: 0.5;
			color: rgb(255, 255, 255);
			padding: 2px 6px;
			display: inline-block;
			border-radius: 10px;
			font-weight: bolder;
			line-height: 10px;
		}
	}

	.payInfoB {
		padding-top: 5px;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 5px;
		min-height: 25px;
		background-color: #ffffff;
		// margin: 0 20px;
		width: 312px;
		margin: 0 auto;
		border-radius: 8px;
		font-size: 13px;
		padding: 10px 10rpx;
		color: black;
		font-size: 15px;
		// line-height: 21px;
		font-weight: 700;
		text-align: left;
		font-family: PingFangSC-Semibold;
	}

	.payInfoText{
		font-weight: 500;font-size: small; color: #666666;
	}
  ::v-deep .van-popup--bottom{
    z-index: 100!important;
  }
</style>
